import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Heading, Box, Image, Flex, Text } from "rebass";
import Link from "components/Link";
import PostPreview from "./PostPreview";
import Signature from "./signature.jpg";

const OpacityLink = (props) => (
  <Box
    {...props}
    as={RouterLink}
    sx={{
      ":hover": {
        opacity: 0.8,
      },
    }}
  />
);

const postUrl = (post) => `/post/${post.slug}`;

const Home = ({ posts }) => {
  const topPost = posts[0] || {};
  return (
    <Flex alignItems="center" justifyContent="center" mb={3}>
      <Box px={5} py={4}>
        <Flex alignItems="center" justifyContent="center">
          <Box
            px={[2, 3, 5]}
            width={["100%", "100%", "800px"]}
            pb={4}
            maxWidth="750px"
          >
            <Text fontSize={4} mb={2}>
              Hello!
            </Text>
            <Text>
              Thank you for visiting our exercise clinic. We want to welcome
              you, tell you about what we do and share some of the stories that
              have led us to be so passionate about the importance of exercise
              in oncology. You’ll find updates from us below about our work and
              the people we work with, as well as some of the things we think
              may be of interest to you.
            </Text>
            <Flex justifyContent="flex-end" pr={5}>
              <Text fontSize={4}>Emily &amp; Chris</Text>
              {/*<Image height="40px" src={Signature} />*/}
            </Flex>
          </Box>
        </Flex>

        <Box mb={3}>
          <Text fontSize={3} color="primary">
            OUR LATEST NEWS
          </Text>
        </Box>
        <Box
          as="hr"
          sx={{
            bg: "primary",
            border: 0,
            height: 1,
          }}
        />
        {posts && (
          <Flex mt={4} mx={-2} py={2} flexWrap="wrap">
            <Box px={2} width={["100%", "100%", 1 / 2]}>
              <OpacityLink to={postUrl(topPost)}>
                <Image src={topPost.feature_image} sx={{ borderRadius: 2 }} />
              </OpacityLink>
              {topPost.tags && (
                <Text fontSize={0} mt={2} color="primary">
                  {topPost.tags[0]?.name.toUpperCase()}
                </Text>
              )}
              <OpacityLink to={postUrl(topPost)}>
                <Heading mt={2}>{topPost.title}</Heading>
              </OpacityLink>
              <OpacityLink to={postUrl(topPost)}>
                <Text mt={2}>{topPost.excerpt}</Text>
              </OpacityLink>
            </Box>
            <Box pl={[2, 2, 3]} mt={[4, 4, 0]} width={["100%", "100%", 1 / 2]}>
              {posts.slice(1, 4).map((post, index) => (
                <PostPreview post={post} key={index} />
              ))}
            </Box>
          </Flex>
        )}
        <Box px={5} pt={3}>
          <Text textAlign="center">
            To read our older updates, <Link to="/posts">click here!</Link>
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default Home;
