import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import api from "utils/ghost-api";
import ReactHtmlParser from "react-html-parser";
import { Button, Heading, Box, Text, Flex, Image } from "rebass";
import { format, parseISO } from "date-fns";
import Link from "components/Link";

const ByLine = ({ author, date }) => (
  <Flex alignItems="center" mt={3} mb={4}>
    <Image src={author.profile_image} variant="avatar" mr={3} />
    <Box>
      <Heading fontSize={2}>{author.name}</Heading>
      <Flex>
        <Text fontSize={1}>{format(parseISO(date), "do MMMM yyyy")}</Text>
        <Text fontSize={1} ml={2}>
          1 min read
        </Text>
      </Flex>
    </Box>
  </Flex>
);

const filterHTML = (html: string) => {
  // Replace ghost auto embed options and suppress related items
  return html.replace(/feature=oembed/g, "rel=0");
};

const GhostPost = () => {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    api.posts
      .read({ slug, include: "tags,authors" })
      .then((post) => setPost(post))
      .catch((err) => {
        console.error(err);
      });
  }, []);

  console.log(filterHTML(post?.html ?? ""));

  return (
    <>
      {post && (
        <Flex flexWrap="wrap">
          <Box pt={[3, 5, 5]} pl={5} width={["100%", "10%", "20%"]}>
            <Link p={0} variant="nav" to="/">
              Home
            </Link>
          </Box>
          <Box
            px={5}
            pt={[2, 5, 5]}
            pb={5}
            width={["100%", "80%", "60%"]}
            color="black"
          >
            <Heading fontSize={5}>{post.title}</Heading>
            <ByLine author={post.primary_author} date={post.published_at} />
            {ReactHtmlParser(filterHTML(post.html))}
          </Box>
        </Flex>
      )}
    </>
  );
};

export default GhostPost;
