import * as React from "react";
import {Helmet} from "react-helmet";

const Meta = ({settings}) => {
    const canonical = "https://theexerciseclinic.org";
    const description = "";
    const title = "TITLE";

    if (!settings) {
        return <></>;
    }

    return (
        <>
            <Helmet>
                <meta name="description" content={settings.description} />
                <meta property="og:site_name" content={settings.title} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={settings.description} />
                <meta property="og:image" content={settings.og_image} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={settings.description} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image" content={settings.twitter_image} />
                {settings.twitter && <meta name="twitter:site" content={`https://twitter.com/${settings.twitter.replace(/^@/, ``)}/`} />}
                {settings.twitter && <meta name="twitter:creator" content={settings.twitter} />}
            </Helmet>
        </>
    );
};

export default Meta;

    