import * as React from "react";
import { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
  useParams,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "emotion-theming";
import { Box, Heading, Flex, Text, Link as RebassLink, Image } from "rebass";
import ReactHtmlParser from "react-html-parser";
import { convertNodeToElement } from "react-html-parser";
import HamburgerMenu from "react-hamburger-menu";
import OffCanvas from "react-aria-offcanvas";
import GhostPage from "./landing/GhostPage";
import GhostPost from "./landing/GhostPost";
import Home from "./landing/Home";

import PatientHub from "./landing/PatientHub";
import api from "utils/ghost-api";
import Link from "./Link";
import Terms from "./landing/Terms";
import Meta from "./landing/Meta";
import Footer from "./landing/Footer";
import AllPosts from "./landing/AllPosts";
import { ECTheme } from "./theme";
import "styles/reset.css";
import "styles/ghost-screen.css";
import "styles/custom.css";

const transform = (node) => {};

const BlogContext = React.createContext({ pages: [] });

const navOrder = [
  "about-us",
  "our-team",
  "training",
  "research-and-education",
  "contact-us",
];

const getPagePos = (page) => {
  const pos = navOrder.indexOf(page.slug);
  return pos > -1 ? pos : navOrder.length;
};

const NavBar = ({ settings }: any) => {
  const blog = useContext(BlogContext);
  const [open, setOpen] = useState(false);
  let location = useLocation();
  const logo = settings.logo;

  useEffect(() => {
    setOpen(false);
  }, [location]);

  const pages = (blog.pages || [])
    .filter(
      (page) =>
        !(page.tags && page.tags.map((tag) => tag.slug).includes("hash-hide"))
    )
    .sort((a, b) => {
      const posA = getPagePos(a);
      const posB = getPagePos(b);

      if (posA < posB) {
        return -1;
      }
      if (posA > posB) {
        return 1;
      }
      return 0;
    });

  return (
    <Flex px={2} color="white" bg="black" alignItems="center">
      <Link variant="nav" to="/">
        {logo ? (
          <Image src={logo} sx={{ height: "16px" }} />
        ) : (
          <Text p={2} fontWeight="bold">
            The Exercise Clinic
          </Text>
        )}
      </Link>
      {blog &&
        pages.map((page) => (
          <Box key={page.id} ml={2} sx={{ display: ["none", "none", "block"] }}>
            <Link variant="nav" color="white" to={`/${page.slug}`}>
              {page.title}
            </Link>
          </Box>
        ))}
      <Box mx="auto" />
      <Link
        variant="nav"
        color="white"
        to="/profile"
        sx={{ display: ["none", "none", "block"] }}
      >
        Patient Hub
      </Link>
      {blog && (
        <Box sx={{ display: ["block", "block", "none"] }}>
          <Box
            pr={2}
            style={{
              cursor: "pointer",
            }}
          >
            <HamburgerMenu
              isOpen={open}
              menuClicked={() => setOpen(!open)}
              width={18}
              height={15}
              strokeWidth={1}
              rotate={0}
              color="white"
              borderRadius={0}
              animationDuration={0.5}
            />
          </Box>
          <OffCanvas
            isOpen={open}
            width="200px"
            height="100%"
            onClose={() => setOpen(false)}
            labelledby="menu-button"
            position="right"
            style={{
              content: {
                backgroundColor: "black",
                padding: "1em",
              },
            }}
          >
            <Box
              pr={2}
              style={{
                cursor: "pointer",
              }}
            >
              <HamburgerMenu
                isOpen={open}
                menuClicked={() => setOpen(!open)}
                width={18}
                height={15}
                strokeWidth={1}
                rotate={0}
                color="white"
                borderRadius={0}
                animationDuration={0.5}
              />
            </Box>
            {pages.map((page) => (
              <Box key={page.id} ml={3}>
                <Link variant="nav" color="white" to={`/${page.slug}`}>
                  {page.title}
                </Link>
              </Box>
            ))}
            <Box ml={3}>
              <Link variant="nav" color="white" to="/profile">
                Patient Hub
              </Link>
            </Box>
          </OffCanvas>
        </Box>
      )}
    </Flex>
  );
};

const Banner = ({ settings }) => {
  const bannerImg = settings.cover_image;
  return (
    <Box
      sx={{
        px: 4,
        py: 6,
        backgroundImage: `url(${bannerImg})`,
        backgroundSize: "cover",
        color: "white",
        bg: "gray",
      }}
    >
      <Heading textAlign="center" fontSize={[5, 6]} color="white">
        The Exercise Clinic
      </Heading>
      <Text textAlign="center">{settings.description}</Text>
    </Box>
  );
};

const Landing = () => {
  const [pages, setPages] = useState([]);
  const [posts, setPosts] = useState([]);
  const [settings, setSettings] = useState({});

  useEffect(() => {
    api.posts
      .browse({ limit: 5, include: "tags,authors" })
      .then((posts) => {
        setPosts(posts);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    api.pages
      .browse({ include: "tags" })
      .then((pages) => {
        setPages(pages);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    api.settings.browse().then((settings) => {
      setSettings(settings);
    });
  }, []);

  return (
    <Router>
      <ThemeProvider theme={ECTheme}>
        <Meta settings={settings} />
        <Box
          sx={{
            fontFamily: "body",
          }}
        >
          <BlogContext.Provider value={{ pages }}>
            <NavBar settings={settings} />
            <Banner settings={settings} />
            <Switch>
              {pages.map((page) => (
                <Route path={`/${page.slug}`} key={page.slug}>
                  <GhostPage page={page} />
                </Route>
              ))}
              <Route path="/post/:slug" children={<GhostPost />} />
              <Route path="/posts">
                <AllPosts />
              </Route>
              <Route path="/profile">
                <PatientHub />
              </Route>
              <Route path="/terms">
                <Terms />
              </Route>
              <Route path="/">
                <Home posts={posts} />
              </Route>
            </Switch>
            <Footer />
          </BlogContext.Provider>
        </Box>
      </ThemeProvider>
    </Router>
  );
};

export default Landing;
