import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Heading, Box, Image, Flex, Text } from "rebass";
import api from "utils/ghost-api";
import Link from "components/Link";
import ReactHtmlParser from "react-html-parser";


const Terms = (props) => {
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    api.pages.read({slug: "terms-and-conditions"})
      .then(page => setTerms(page))
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <Flex alignItems="center" justifyContent="center" mb={3}>
      <Box px={5} py={4}>
        <Heading>Our Privacy Policy</Heading>
        {terms && (
          <>
            { ReactHtmlParser(terms.html) }
          </>
        )}
      </Box>
    </Flex>
  );
};

export default Terms;
