import * as React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Heading, Box, Image, Flex, Text } from "rebass";
import Link from "components/Link";
import api from "utils/ghost-api";
import PostPreview from "./PostPreview";

const OpacityLink = (props) => (
  <Box
    {...props}
    as={RouterLink}
    sx={{
      ":hover": {
        opacity: 0.8,
      },
    }}
  />
);

const postUrl = (post) => `/post/${post.slug}`;

const Pagination = ({ page, setPage, pages }) => {
  const startShow = 1;
  const endShow = 1;
  const eitherSideShow = 1;

  const pagesToShow = [];

  for (let i = 0; i < startShow; i++) {
    pagesToShow.push(1 + i);
  }
  for (let i = 0; i < endShow; i++) {
    pagesToShow.push(pages - i);
  }
  for (let i = 0; i < eitherSideShow; i++) {
    pagesToShow.push(page - (i + 1));
    pagesToShow.push(page + (i + 1));
  }
  pagesToShow.push(page);
  const uniquePages = pagesToShow
    .filter((a, b) => pagesToShow.indexOf(a) === b)
    .filter((page) => page > 0 && page <= pages);
  const sortedPages = uniquePages.sort();

  return (
    <Box>
      <Text fontSize={1} mb={1} textAlign="center">Pages</Text>
      <Flex alignItems="center" justifyContent="center">
        <Box
          color={(page === 1) ? "grey" : "black"}
          sx={{
            ":hover": {
              color: (page === 1) ? "grey" : "secondary",
            },
          }}
          style={{ cursor: "pointer" }}
          width="2em"
          onClick={() => {
            if (page > 1) {
              setPage(page - 1);
            }
          }}
        >
          <Heading textAlign="center" fontSize={3}>{"<"}</Heading>
        </Box>
        {sortedPages.map((pageNo, idx) => {
          const active = page === pageNo;
          return (
            <React.Fragment key={pageNo}>
              {idx > 0 && idx < pages && sortedPages[idx - 1] != pageNo - 1 && (
                <Box width="2em" key={`${pageNo}-dots`}>
                  <Heading textAlign="center" fontSize={3}>...</Heading>
                </Box>
              )}
              <Box
                key={pageNo}
                sx={{
                  ":hover": {
                    color: active ? "white" : "secondary",
                  },
                }}
                style={{
                  cursor: "pointer",
                  borderColor: "black",
                  borderWidth: 1,
                }}
                width="2em"
                p={2}
                ml={1}
                bg={active ? "primary" : "white"}
                color={active ? "white" : "black"}
                onClick={() => setPage(pageNo)}
              >
                <Heading textAlign="center" fontSize={3}>{pageNo}</Heading>
              </Box>
            </React.Fragment>
          );
        })}
        <Box
          color={(page === pages) ? "grey" : "black"}
          sx={{
            ":hover": {
              color: (page === pages) ? "grey" : "secondary",
            },
          }}
          style={{ cursor: "pointer" }}
          width="2em"
          onClick={() => {
            if (page < pages) {
              setPage(page + 1);
            }
          }}
        >
          <Heading fontSize={3} textAlign="center">{">"}</Heading>
        </Box>
      </Flex>
    </Box>
  );
};

const AllPosts = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [firstView, setFirstView] = useState(true);
  const [pageMeta, setPageMeta] = useState({
    pagination: {
      pages: 1,
      limit: 0,
    },
  });

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   
  const myRef = useRef(null)

  useEffect(() => {
    api.posts
      .browse({ limit: 5, page, include: "tags,authors" })
      .then((posts) => {
        setPosts(posts);
        if (!firstView) {
          scrollToRef(myRef);
        } else {
          setFirstView(false);
        }
        setPageMeta(posts.meta);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [page]);

  return (
    <Flex flexWrap="wrap">
      <Box pt={[3, 5, 5]} pl={5} width={["100%", "10%", "20%"]}>
        <Link p={0} variant="nav" to="/">
          Home
        </Link>
      </Box>
      <Box
        px={5}
        pt={[2, 5, 5]}
        pb={5}
        width={["100%", "80%", "60%"]}
        color="black"
      >
        <Heading fontSize={5} mb={3} ref={myRef}>
          All our updates!
        </Heading>

        {posts.map((post, index) => (
          <PostPreview post={post} key={index} />
        ))}
        {pageMeta.pagination.pages > 1 && (
          <Pagination
            page={page}
            setPage={setPage}
            pages={pageMeta.pagination.pages}
          />
        )}
      </Box>
    </Flex>
  );
};

export default AllPosts;
