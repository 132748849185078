import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Heading, Box, Image, Flex, Text } from "rebass";
import Link from "components/Link";

const Footer = (props) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Text fontSize={0} mb={2} mt={3}>&copy; 2020 The Exercise Clinic. See our privacy policy <Link to={'/terms'}>here</Link>.</Text>
    </Flex>
  );
};

export default Footer;
