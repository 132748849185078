import theme from "@rebass/preset";

export const ECTheme = {
  ...theme,
  fonts: {
    body: "Open Sans, sans-serif",
    heading: "Montserrat, sans-serif",
    monospace: "Menlo, monospace",
  },
  breakpoints: ["40em", "56em", "64em"],
  colors: {
    text: "#1d1d1d",
    black: "#1d1d1d",
    background: "#fff",
    primary: "#000080",
    // primary: "#33e",
    secondary: "#FFAB37",
  },
  variants: {
    ...theme.variants,
    nav: {
      ...theme.variants.nav,
      ":hover,:focus,.active": {
        color: "secondary",
        textDecoration: "none",
      },
    },
  },
};
