import GhostContentAPI from "@tryghost/content-api";


const api = new (GhostContentAPI as any)({
  url: "https://michaelcurtis.ghost.io",
  key: "c48d661b7a769f4f4fd0ba71d5",
  version: "v3",
});


export default api;
