import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Heading, Box, Image, Flex, Text } from "rebass";
import Link from "components/Link";

const PatientHub = (props) => {
  return (
    <Flex alignItems="center" justifyContent="center" mb={3}>
      <Box px={5} py={4}>
        <Heading>Our patient hub is coming soon!</Heading>
        <Text mt={2}>We're working hard to bring you personalised clinical exercise directly to you.</Text>
        <Text mt={2}>Check back soon for more information!</Text>
      </Box>
    </Flex>
  );
};

export default PatientHub;
