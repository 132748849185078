import * as React from "react";
import { Box, Heading, Flex, Text, Link as RebassLink } from "rebass";
import ReactHtmlParser from "react-html-parser";


const GhostPage = ({ page }) => (
  <Flex pt={3} pb={5} flexWrap="wrap">
    <Box p={3} width={["100%", "30%", "30%"]} color="primary" bg="white">
      <Heading textAlign={["left", "right", "right"]}>{page.title}</Heading>
    </Box>
    <Box p={3} width={["100%", "70%", "50%"]} color="black">
      { ReactHtmlParser(page.html) }
    </Box>
  </Flex>
);

export default GhostPage;
