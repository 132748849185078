import * as React from "react";
import { Heading, Box, Image, Flex, Text } from "rebass";
import { Link as RouterLink } from "react-router-dom";

const OpacityLink = (props) => (
  <Box {...props} as={RouterLink} sx={{ ':hover': {
      opacity: 0.8,
    }}}/>
);

const postUrl = (post) => `/post/${post.slug}`;

const PostPreview = ({post}: any) => (
  <Flex mb={4}>
    <Box width="30%">
      <OpacityLink to={postUrl(post)}>
        <Image src={post.feature_image} sx={{borderRadius: 2}} />
      </OpacityLink>
    </Box>
    <Box width="70%" pl={3}>
      {post.tags && (
        <Text fontSize={0} color="primary" lineHeight={1}>
          {post.tags[0]?.name.toUpperCase()}
        </Text>
      )}

      <OpacityLink to={postUrl(post)}>
        <Heading mt={2} fontSize={2} color="black" bg="white">
          {post.title}
        </Heading>
      </OpacityLink>

      <OpacityLink to={postUrl(post)}>
        <Text
          my={1}
          fontSize={1}
          lineHeight={1.4}
          color="black"
          bg="white"
        >
          {post.excerpt}
        </Text>
      </OpacityLink>
    </Box>
  </Flex>
);

export default PostPreview;
